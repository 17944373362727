<template>
  <div class="actions">
    <BrandButton
      :class="routes.priceList != activeRoute ? 'notActive' : 'active'"
      :target="routes.priceList"
      as="router-link"
      @click.native="dataLayer()"
    >
      <VueIcon
        iconName="IconSheetLines"
        class="icon"
        :iconColor="routes.priceList == activeRoute ? '#23303d' : '#79838e'"
      ></VueIcon>
      <VueText sizeLevel="4" weightLevel="4">GÜNCEL FİYAT LİSTESİ</VueText>
    </BrandButton>
    <BrandButton
      :class="routes.productList != activeRoute ? 'notActive' : 'active'"
      :target="routes.productList"
      as="router-link"
    >
      <VueIcon
        iconName="IconSpeechBubble"
        class="icon"
        :iconColor="routes.productList == activeRoute ? '#23303d' : '#79838e'"
      ></VueIcon>
      <VueText sizeLevel="4" weightLevel="4">GÜNCEL ÜRÜN LİSTESİ</VueText>
    </BrandButton>
  </div>
</template>
<script>
import BrandButton from '@/components/brand/BrandButton/BrandButton.vue';
import VueText from '@/components/shared/VueText/VueText.vue';
import VueIcon from '@/components/shared/VueIcon/VueIcon.vue';
import { ICON_VARIABLES, COMPONENT_SIZES } from '@/constants/component.constants';
import gtmUtils from '@/mixins/gtmUtils.js';

export default {
  name: 'BrandProductPriceToolbar',
  mixins: [gtmUtils],
  data() {
    return {};
  },
  props: {
    partner: {
      type: String,
      required: true,
    },
    routes: {
      type: Object,
      required: true,
    },
    activeRoute: {
      type: String,
      required: true,
    },
  },
  components: {
    BrandButton,
    VueText,
    VueIcon,
  },
  computed: {
    icon() {
      return {
        sheetLines: ICON_VARIABLES.sheetLines,
        speechBubble: ICON_VARIABLES.speechBubble,
      };
    },
    sizes() {
      return COMPONENT_SIZES;
    },
  },
  methods: {
    dataLayer() {
      this.pushDataLayerEvent('fiyat_listesi_click', {
        location: 'business-partner',
        partner: this.partner,
      });
    },
  },
};
</script>
<style scoped lang="scss">
.icon {
  position: relative;
  top: 3px;
}
.actions {
  display: flex;
  padding: palette-space-level(20);
  z-index: 5;
  justify-content: center;
  margin-bottom: 30px;
  a {
    width: inherit !important;
    background: palette-color(white-100);
  }
  .notActive {
    position: relative;
    span {
      p {
        color: palette-color(grey-30);
      }
    }
  }
  .active {
    position: relative;
    z-index: 2;
    span {
      p {
        color: palette-color(grey-40);
      }
    }
    &:after {
      content: '';
      width: 100%;
      height: 2px;
      position: absolute;
      z-index: 3;
      bottom: 0;
      background-image: linear-gradient(271deg, #d20051, #ffa300);
    }
  }
}
</style>
